/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrDependencyPeople { 
    id?: string;
    staff_id?: string;
    full_name?: string;
    dependency_category_id?: string;
    birthday?: Date;
    birthday_number?: string;
    birthday_book_number?: string;
    birthday_register_place?: string;
    id_card?: string;
    issue_place?: string;
    issue_date?: Date;
    affect_from?: Date;
    affect_to?: Date;
    approved?: boolean;
    approver_id?: string;
    approved_date?: Date;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    code?: string;
    company?: string;
}