import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
// import {Contacts, EmailType, PhoneType} from '@capacitor-community/contacts';
import {Contacts, ContactField, ContactName} from '@ionic-native/contacts';
@Component({
  selector: 'kt-dialog-component',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  contactInfo = {name: null, email: null, number: null};
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataModal: any,
    private contacts: Contacts // private contact: Contact
  ) {}

  ngOnInit() {}
  onOpenApp(): void {
    window.open(this.dataModal.url, '_blank');
  }
  onOpenTelephoneBook(): void {
    try {
      window.open('tel:' + this.dataModal.cell_phone, '_blank');
    } catch (err) {
      alert('err' + err);
    }
  }
  onSavePhone(): void {
    try {
      window.open(this.dataModal.user_phoneLink, '_blank');
    } catch (err) {
      alert('err' + err);
    }
  }
  onInstallApp() {}
}
