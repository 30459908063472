/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WorkflowActivity { 
    id?: string;
    name?: string;
    working_group_id?: string;
    workflow_id?: string;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    is_initial?: boolean;
    is_final?: boolean;
    activity_category_id?: string;
    working_shift_id?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    deleted?: boolean;
    unique_id?: string;
    max_processing_time?: number;
    selected_trasition_unique_id?: string;
    completed_percent?: number;
    is_wrapup_layout?: boolean;
    is_parralel_forked?: boolean;
    is_parralel_merge?: boolean;
    need_presenter?: boolean;
    need_confirm_sign?: boolean;
    need_internal_approve?: boolean;
    code?: string;
}