// Angular
import {OverlayModule} from '@angular/cdk/overlay';
import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, Injectable, NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {BASE_PATH} from './variables';
// Angular in memory
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// Perfect Scroll bar

// Env
import {environment} from '../environments/environment';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {EffectsModule} from '@ngrx/effects';
import {
  DefaultRouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {reducers} from './core/reducers';
// Copmponents
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ThemeModule} from './views/theme/theme.module';
// Partials
import {ApiModule} from '@app/api.module';
// Toastr
import {ToastrModule} from 'ngx-toastr';

import {initializeApp} from 'firebase/app';

import {CommonModule} from '@angular/common';
if (environment.firebase) {
  initializeApp(environment.firebase);
}

@Injectable()
export class GlobalVariables {
  testVal = '#00bcd4';
  Quepal = {
    default: `linear-gradient(135deg,#11998e 0%,#38ef7d 100%)`,
    contrast: '#fff',
    shadow: '#11998e',
  };
  SublimeLight = {
    default: `linear-gradient(135deg,#FC5C7D 0%,#6A82FB 100%)`,
    contrast: '#fff',
    shadow: '#B36FBC',
  };
  Amber = {
    default: '#ffc107',
    contrast: 'rgba(0, 0, 0, 0.87)',
  };
}

// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService,
} from './core/_base/layout';
// Auth
import {AuthService} from './core/auth';
import {AuthModule} from './views/pages/auth/auth.module';
// CRUD
import {HttpUtilsService, TypesUtilsService} from './core/_base/crud';
// Config
import {LayoutConfig} from './core/_config/layout.config';
// Highlight JS
//import {HIGHLIGHT_OPTIONS, HighlightLanguage} from 'ngx-highlightjs';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//Summernote
//Permissions
import {ModalModule} from 'ngx-bootstrap/modal';
import {CanActivateRouteGuard} from './core/auth/_guards/permissions-route.guard';

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs);
    }
  };
}

// export function hljsLanguages(): HighlightLanguage[] {
//   return [
//     {name: 'typescript', func: typescript},
//     {name: 'scss', func: scss},
//     {name: 'xml', func: xml},
//     {name: 'json', func: json},
//   ];
// }

import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';
import {RouteReuseStrategy} from '@angular/router';
import {CheckHashRouteGuard} from './core/auth/_guards/checkHash.guard';
import {HashRouteGuard} from './core/auth/_guards/hashUrl.guard';
import {ClientPluginLoaderService} from './services/plugin-loader/client-plugin-loader.service';
import {PluginLoaderService} from './services/plugin-loader/plugin-loader.service';
import {PluginsConfigProvider} from './services/plugins-config.provider';
import {RouteReuseService} from './services/route-reuse.service';
import {BusinessCardService} from './views/pages/business-card/service/business-card.service';
import {SharedModule} from './views/shared/shared.module';
// import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {Contacts} from '@ionic-native/contacts';
import {DialogComponent} from './views/pages/material/popups-and-modals/dialog/dialog.component';
@NgModule({
  declarations: [AppComponent],
  imports: [
    ApiModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    CoreModule,
    OverlayModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictActionImmutability: true, // disable it to avoid the error
        strictStateImmutability: false,
      },
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      stateKey: 'router',
    }),
    StoreDevtoolsModule.instrument(),
    AuthModule.forRoot(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    ThemeModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
    }),
    ModalModule.forRoot(),
    SharedModule,
    // MatButtonModule,
    MatDialogModule,
    CommonModule,
    //BrowserTransferStateModule
  ],
  exports: [NgxMaterialTimepickerModule],
  providers: [
    AuthService,
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,

    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true,
    },
    // {
    //   provide: HIGHLIGHT_OPTIONS,
    //   useValue: {languages: hljsLanguages},
    // },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,

    // global variables
    {provide: BASE_PATH, useValue: environment.backendhost},
    //permission
    CanActivateRouteGuard,
    HashRouteGuard,
    CheckHashRouteGuard,
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {touchGestures: 'off'}},
    {provide: PluginLoaderService, useClass: ClientPluginLoaderService},
    PluginsConfigProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: (provider: PluginsConfigProvider) => () =>
        provider
          .loadConfig()
          .toPromise()
          .then((config) => (provider.config = config)),
      multi: true,
      deps: [PluginsConfigProvider],
    },
    //Route reuse
    {
      provide: RouteReuseStrategy,
      useClass: RouteReuseService,
    },
    BusinessCardService,
    Contacts,
  ],
  entryComponents: [DialogComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
