import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DatePipe} from './pipe/date.pipe';
import {GenderPipe} from './pipe/gender.pipe';
import {FormErrorService} from './services/form-error.service';
import {FullNameService} from './services/full-name.service';
import {Title} from '@angular/platform-browser';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/views/shared/constants/constants';

@NgModule({
  declarations: [DatePipe, GenderPipe],
  imports: [CommonModule],
  providers: [
    FormErrorService,
    FullNameService,
    Title,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  exports: [DatePipe, GenderPipe],
})
export class SharedModule {}
