/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FileDownloadMngt { 
    id?: string;
    physical_path?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    max_download_count?: number;
    file_name?: string;
    is_encrypted?: boolean;
    expiry?: Date;
}