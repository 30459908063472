/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrTimesheet { 
    id?: string;
    project_id?: string;
    task_type_id?: string;
    task_name?: string;
    task_notes?: string;
    timesheet_week_id?: string;
    mon?: number;
    tue?: number;
    wed?: number;
    thu?: number;
    fri?: number;
    sat?: number;
    sun?: number;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
}