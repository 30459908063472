// Angular
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Components
import {BaseComponent} from './views/theme/base/base.component';

import {ErrorPageComponent} from './views/theme/content/error-page/error-page.component';
// Auth
import {BusinessCardComponent} from './views/pages/business-card/business-card.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('app/views/pages/auth/auth.module').then((m) => m.AuthModule),
  },
  // {path: 'portal/hr', redirectTo: 'error/404', pathMatch: 'full'},
  // {
  //   path: 'portal/hr',
  //   component: MeuBaseComponent,
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import(
  //           'app/views/areas/portal/hr/recruitment/recruitment.module'
  //         ).then((m) => m.RecruitmentModule),
  //     },

  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/inoutlog/inoutlog.module').then(
  //           (m) => m.InoutlogModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/leave/leave.module').then(
  //           (m) => m.HRLeaveModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/ot/ot.module').then(
  //           (m) => m.OtModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/absent/absent.module').then(
  //           (m) => m.HRAbsentModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import(
  //           'app/views/areas/portal/hr/user-management/user-management.module'
  //         ).then((m) => m.UserManagementModule),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import(
  //           'app/views/areas/portal/hr/user-template-management/user-template-management.module'
  //         ).then((m) => m.HRUserTemplateModule),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/setting/setting.module').then(
  //           (m) => m.SettingModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import(
  //           'app/views/areas/portal/hr/working-shift/working-shift.module'
  //         ).then((m) => m.HRWorkingShift),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/salary/salary.module').then(
  //           (m) => m.HRSalaryModule
  //         ),
  //     },
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import(
  //           'app/views/areas/portal/hr/employee-contact/employee-contact.module'
  //         ).then((m) => m.EmployeeContactModule),
  //     },
  //     {
  //       path: 'error/403',
  //       component: ErrorPageComponent,
  //       data: {
  //         type: 'error-v6',
  //         code: 403,
  //         title: '403... Access forbidden',
  //         desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
  //       },
  //     },
  //   ],
  // },
  {
    path: 'business-card',
    component: BusinessCardComponent,
  },
  {
    path: 'error/404',
    component: ErrorPageComponent,
    data: {
      type: 'error-v6',
      code: 404,
      title: '404... Not found',
    },
  },
  {
    path: 'error/somethingwrong',
    component: ErrorPageComponent,
    data: {
      type: 'error-v6',
      code: 404,
      title: 'Oops...',
      desc: ' Có lỗi xảy ra, vui lòng thử lại!',
    },
  },
  // {
  //   path: '',
  //   component: BaseComponent,
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: 'dashboard',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/hr/dashboard/dashboard.module').then(
  //           (m) => m.DashboardModule
  //         ),
  //     },

  //     {
  //       path: 'error/403',
  //       component: ErrorPageComponent,
  //       data: {
  //         type: 'error-v6',
  //         code: 403,
  //         title: '403... Access forbidden',
  //         desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
  //       },
  //     },
  //     {path: 'error/:type', component: ErrorPageComponent},
  //     {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  //   ],
  // },
  // {
  //   path: 'portal/workflow',
  //   component: WorkflowLayoutComponent,
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/workflow/workflow.module').then(
  //           (m) => m.WorkflowModule
  //         ),
  //     },
  //   ],
  // },
  // {
  //   path: 'portal/workflow/workitem/:itemCode/status',
  //   component: WorkitemStatusLayoutComponent,
  //   //canActivate: [AuthGuard],
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('app/views/areas/portal/workflow/workflow.module').then(
  //           (m) => m.WorkflowModule
  //         ),
  //     },
  //   ],
  // },
  {
    path: '',
    component: BaseComponent,
    //canActivate: [AuthGuard],
    children: [
      {path: 'error/:type', component: ErrorPageComponent},
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {path: '**', redirectTo: 'error/404', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
