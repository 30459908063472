export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
  public set setConfigProcessedBadge(val) {
    this.defaults.header.items[1].badge.value = val;
  }
  public set setConfigApprovedBadge(val) {
    this.defaults.header.items[2].badge.value = val;
  }
  public set setConfigMyProcessedBadge(val) {
    this.defaults.header.items[3].badge.value = val;
  }
}
