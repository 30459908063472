/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface LoginOtp { 
    id?: string;
    user_id?: string;
    otp_login?: string;
    expired?: Date;
    relogin_reason?: string;
    created_at?: Date;
    created_by?: string;
    approved?: boolean;
    device_id?: string;
    device_model?: string;
    used?: boolean;
}