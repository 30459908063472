import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BusinessCardService {
  private apiUrl =
    'https://clbdoanhnhanhotran.meu-solutions.com/api/users/businessCard';
  private baseUrlApi = 'https://clbdoanhnhanhotran.meu-solutions.com/api';
  constructor(private http: HttpClient) {}

  getBusinessCard(secretKey: string, id: string): Observable<any> {
    const url = `${this.apiUrl}/${secretKey}?id=${id}`;
    return this.http.get(url);
  }
  getAlbumByUserID(id: string) {
    const url = `${this.baseUrlApi}/album/${id}`;
    return this.http.get(url);
  }
}
