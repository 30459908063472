/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrInOutDrawLog { 
    id?: string;
    devide_code?: string;
    time?: Date;
    int_out_mode?: number;
    batch_id?: string;
    processed?: boolean;
    user_id?: string;
    attendance_code?: number;
}