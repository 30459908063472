// Angular
import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
// RxJS
import {Observable} from 'rxjs';
import {tap, finalize} from 'rxjs/operators';
import {Router} from '@angular/router';
import {environment} from '@app/../environments/environment';
import {RootSpinnerService} from '@app/services/root-spinner.service';
import {HashService} from '@app/core/auth/_services/hash.service';
//import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
  now = Date.now();
  constructor(private router: Router, private hashService: HashService) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(
          environment.authTokenKey
        )}`,
        Signature: this.hashService.encryptData({
          url: request.urlWithParams.replace('//', '/'),
          body: JSON.stringify(request.body),
          time: this.now,
        }),
        Time: this.now.toString(),
      },
    });
    if (request.method != 'GET') {
      RootSpinnerService.show();
    }
    return next.handle(request).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // http response status code
            if (
              this.hashService.encryptData(event.body) !==
              event.headers.get('Signature')
            ) {
              // this.router.navigate(['/error/error-v6']);
            }
          }
        },
        (error) => {
          // http response status code
          // tslint:disable-next-line:no-debugger
          //Redirect to login when invalid token
          if (error.status === 401) {
            RootSpinnerService.hide();
            localStorage.removeItem(environment.authTokenKey);
            if (this.router.url !== '' && this.router.url.trim() !== '/') {
              if (this.router.url == '/portal/hr/joblist') return;
              if (this.router.url.includes('/portal/hr/joblist/jobdetail/'))
                return;

              if (!this.router.url.includes('returnUrl')) {
                // this.router.navigate(['/auth/login'], {
                //   queryParams: {returnUrl: this.router.url},
                // });
              }
            }
          }
        }
      ),
      finalize(() => {
        if (request.method !== 'GET') {
          RootSpinnerService.hide();
        }
      })
    );
  }
}
