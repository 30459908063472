import {NgxPermissionsModule} from 'ngx-permissions';
// Angular
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
// Angular Material
import {MatButtonModule} from '@angular/material/button';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
// NgBootstrap
import {
  NgbDropdownModule,
  NgbProgressbarModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
// Translation
import {TranslateModule} from '@ngx-translate/core';
// Loading bar
import {LoadingBarModule} from '@ngx-loading-bar/core';
// NGRX
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
// Ngx DatePicker
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
// Perfect Scrollbar
// SVG inline
// Core Module
import {
  PermissionEffects,
  permissionsReducer,
  RoleEffects,
  rolesReducer,
} from '../../core/auth';
import {CoreModule} from '../../core/core.module';
import {PagesModule} from '../pages/pages.module';
import {AsideLeftComponent} from './aside/aside-left.component';
import {BaseComponent} from './base/base.component';
import {BrandComponent} from './brand/brand.component';
import {ErrorPageComponent} from './content/error-page/error-page.component';
import {FooterComponent} from './footer/footer.component';
import {MeuFooterComponent} from './footer/meu-footer/meu-footer.component';
import {HeaderMobileComponent} from './header/header-mobile/header-mobile.component';
import {HeaderComponent} from './header/header.component';
import {MenuHorizontalComponent} from './header/menu-horizontal/menu-horizontal.component';
import {MeuTopbarHeaderComponent} from './header/meu-topbar-header/meu-topbar-header.component';
import {HtmlClassService} from './html-class.service';
import {MeuBaseComponent} from './meu-base/meu-base.component';
import {SubheaderComponent} from './subheader/subheader.component';
import {WorkitemStatusLayoutComponent} from './workitem-status/workitem-status.component';

@NgModule({
  declarations: [
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,

    MeuBaseComponent,

    MeuTopbarHeaderComponent,

    MeuFooterComponent,
    WorkitemStatusLayoutComponent,
  ],
  exports: [
    MeuBaseComponent,
    BaseComponent,
    FooterComponent,

    // headers
    HeaderComponent,
    BrandComponent,
    HeaderMobileComponent,

    // subheader
    SubheaderComponent,

    // aside left menu components
    AsideLeftComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    ErrorPageComponent,
    WorkitemStatusLayoutComponent,
  ],
  providers: [HtmlClassService],
  imports: [
    CommonModule,
    RouterModule,
    NgxPermissionsModule.forChild(),
    StoreModule.forFeature('roles', rolesReducer),
    StoreModule.forFeature('permissions', permissionsReducer),
    EffectsModule.forFeature([PermissionEffects, RoleEffects]),
    PagesModule,
    CoreModule,
    FormsModule,
    MatProgressBarModule,
    MatTabsModule,
    MatButtonModule,
    MatTooltipModule,
    TranslateModule.forChild(),
    LoadingBarModule,
    NgxDaterangepickerMd,

    // ng-bootstrap modules
    NgbDropdownModule,
    NgbProgressbarModule,
    NgbTooltipModule,
  ],
})
export class ThemeModule {}
