export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Dashboards',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Tổng quan',
              bullet: 'dot',
              page: '/dashboard',
              icon: 'flaticon-analytics',
            },
            {
              title: 'Cá nhân',
              bullet: 'dot',
              page: '/dashboard/custom-dashboard',
              icon: 'flaticon-graphic-1',
            },
          ],
        },
        {
          title: 'Nhân sự',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Hồ sơ nhân sự',
              bullet: 'dot',
              permissions: 'HR_EMPLOYEE',
              page: '/portal/hr/user-management',
              icon: 'flaticon-users',
            },
            {
              title: 'Biểu mẫu nhân sự',
              bullet: 'dot',
              permissions: 'HR_EMPLOYEE',
              page: '/portal/hr/appusertemplate',
              icon: 'flaticon-file',
              hideMobile: true,
            },
            {
              title: 'Danh bạ nhân viên',
              bullet: 'dot',
              page: '/portal/hr/employee-contact',
              icon: 'flaticon-users',
            },
            {
              title: 'Sơ đồ tổ chức',
              page: '/portal/hr/setting/departments',
              icon: 'flaticon-map',
              // permissions: 'HR_EMPLOYEE',
              hideMobile: true,
            },
          ],
        },
        {
          title: 'Lương',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Lương của tôi',
              bullet: 'dot',
              page: '/portal/hr/salary/payslip',
              icon: 'flaticon-coins',
            },
            {
              title: 'Duyệt chấm công',
              bullet: 'dot',
              page: '/portal/hr/salary/confirmattendance',
              icon: 'flaticon-list-2',
              hideMobile: true,
            },
            {
              title: 'Quản lý sổ lương',
              bullet: 'dot',
              page: '/portal/hr/salary/books',
              icon: 'flaticon-app',
              permissions: 'HR_SALARY',
              hideMobile: true,
            },
          ],
        },
        {
          title: 'Nghỉ phép',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Quản lý nghỉ phép',
              bullet: 'dot',
              page: '/portal/hr/leave-management',
              icon: 'flaticon-event-calendar-symbol',
            },
            {
              title: 'Thống kê nghỉ phép',
              page: '/portal/hr/leave/leavestatistics',
              bullet: 'dot',
              icon: 'flaticon-time-1',
              permissions: 'HR_EMPLOYEE',
            },
          ],
        },
        {
          title: 'Tăng ca',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Quản lý tăng ca',
              bullet: 'dot',
              page: '/portal/hr/ot/ot-management',
              icon: 'flaticon-time-2',
            },
            {
              title: 'Thống kê làm thêm giờ',
              bullet: 'dot',
              page: '/portal/hr/ot/otstatistics',
              icon: 'flaticon-clipboard',
              permissions: 'HR_EMPLOYEE',
            },
          ],
        },
        {
          title: 'Xác nhận ngày công',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Xác nhận ngày công',
              page: '/portal/hr/absent/absent-manage',
              bullet: 'dot',
              icon: 'flaticon-calendar-2',
            },
            {
              title: 'Thống kê xác nhận ngày công',
              page: '/portal/hr/absent/absentstatistics',
              bullet: 'dot',
              icon: 'flaticon-diagram',
              permissions: 'HR_EMPLOYEE',
            },
          ],
        },
        {
          title: 'Công nhật',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
            {
              title: 'Timesheet của tôi',
              bullet: 'dot',
              page: '/portal/hr/mytimesheet',
              icon: 'flaticon-stopwatch',
            },
            {
              title: 'Bảng chấm công của tôi',
              bullet: 'dot',
              page: '/portal/hr/inoutlog/myinoutlog',
              icon: 'flaticon-calendar',
            },
            {
              title: 'Bảng chấm công công ty',
              bullet: 'dot',
              page: '/portal/hr/inoutlog/inoutlogdaily',
              icon: 'flaticon-calendar-1',
              permissions: 'HR_EMPLOYEE',
              hideMobile: true,
            },
            {
              title: 'Nhật kí ra vào',
              bullet: 'dot',
              page: '/portal/hr/inoutlog/history',
              icon: 'flaticon-interface-4',
              permissions: 'HR_EMPLOYEE',
              hideMobile: true,
            },
          ],
        },
        {
          title: 'Tuyển dụng',
          root: true,
          alignment: 'left',
          toggle: 'click',
          hideMobile: true,
          submenu: [
            {
              title: 'Yêu cầu tuyển dụng',
              bullet: 'dot',
              icon: 'flaticon-menu-button',
              page: '/portal/hr/recruitment/myrecruitment',
            },
            {
              title: 'Vị trí đang mở tuyển',
              page: '/portal/hr/recruitment/listvacancy',
              bullet: 'dot',
              icon: 'flaticon-signs-1',
              permissions: 'HR_RECRUITMENT',
            },
            {
              title: 'Hồ sơ ứng viên',
              page: '/portal/hr/recruitment/listcandidate',
              bullet: 'dot',
              icon: 'flaticon-profile',
              permissions: 'HR_RECRUITMENT',
            },
            {
              title: 'Phỏng vấn ứng viên',
              page: '/portal/hr/recruitment/interview',
              bullet: 'dot',
              icon: 'flaticon-speech-bubble',
            },
            {
              title: 'Quy trình tuyển dụng',
              bullet: 'dot',
              icon: 'flaticon-settings',
              permissions: 'HR_RECRUITMENT',
              submenu: [
                {
                  title: 'Quy trình tuyển dụng',
                  page: '/portal/hr/recruitment/recruitprocess',
                  bullet: 'dot',
                  icon: 'flaticon-settings',
                  permission: 'accessToECommerceModule',
                },
                {
                  title: 'Các bước tuyển dụng',
                  bullet: 'dot',
                  icon: 'flaticon-security',
                  page: '/portal/hr/recruitment/processsteps',
                },
                {
                  title: 'Tiêu chí tuyển dụng',
                  page: '/portal/hr/recruitment/stepcriteria',
                  bullet: 'dot',
                  icon: 'flaticon-list-1',
                },
                {
                  title: 'Bộ đáp án',
                  page: '/portal/hr/recruitment/evaluationcategory',
                  bullet: 'dot',
                  icon: 'flaticon-tabs',
                },
              ],
            },
            {
              title: 'Tin tuyển dụng',
              page: '/portal/hr/recruitment/joblist',
              bullet: 'dot',
              icon: 'flaticon-signs-1',
              permissions: 'HR_RECRUITMENT',
            },
            {
              title: 'Danh sách hồ sơ ứng tuyển',
              page: '/portal/hr/recruitment/applicantlist',
              bullet: 'dot',
              icon: 'flaticon-profile',
              permissions: 'HR_RECRUITMENT',
            },
          ],
        },
        {
          title: 'Cài đặt',
          root: true,
          alignment: 'left',
          toggle: 'click',
          permissions: 'HR_ADMIN',
          hideMobile: true,
          submenu: [
            {
              title: 'Loại phòng ban',
              bullet: 'dot',
              icon: 'flaticon-web',
              page: '/portal/hr/setting/departmenttype',
              // submenu: [
              //   {
              //     title: 'Loại phòng ban',
              //     page: '/portal/hr/setting/departmenttype',
              //     icon: 'flaticon-symbol',
              //   },

              // ],
            },
            {
              title: 'Văn phòng',
              bullet: 'dot',
              icon: 'flaticon-map',
              submenu: [
                {
                  title: 'Loại văn phòng',
                  page: '/portal/hr/setting/officetype',
                  icon: 'flaticon-symbol',
                },
                {
                  title: 'Văn phòng',
                  page: '/portal/hr/setting/offices',
                  icon: 'flaticon-web',
                },
              ],
            },
            {
              title: 'Chức vụ',
              bullet: 'dot',
              icon: 'flaticon-avatar',
              submenu: [
                {
                  title: 'Loại chức vụ',
                  page: '/portal/hr/setting/positiontype',
                  icon: 'flaticon-business',
                },
                {
                  title: 'Chức vụ',
                  page: '/portal/hr/setting/positions',
                  icon: 'flaticon-user-settings',
                },
              ],
            },
            {
              title: 'Thông tin bổ sung',
              bullet: 'dot',
              icon: 'flaticon-more-v6',
              submenu: [
                {
                  title: 'Loại chứng chỉ',
                  page: '/portal/hr/setting/certificationcategory',
                  icon: 'flaticon-interface-6',
                },
                {
                  title: 'Loại bằng cấp',
                  page: '/portal/hr/setting/degreecategory',
                  icon: 'flaticon-interface-5',
                },
                {
                  title: 'Loại người phụ thuộc',
                  page: '/portal/hr/setting/dependencycategory',
                  icon: 'flaticon-technology-1',
                },
                {
                  title: 'Danh mục ngân hàng',
                  page: '/portal/hr/setting/bankmanagement',
                  icon: 'flaticon-interface-7',
                },
                {
                  title: 'Loại trạng thái',
                  page: '/portal/hr/setting/userstatus',
                  icon: 'flaticon-information',
                },
              ],
            },
            {
              title: 'Nghỉ lễ - Nghỉ phép',
              bullet: 'dot',
              icon: 'flaticon-calendar-2',
              submenu: [
                {
                  title: 'Nghỉ lễ',
                  page: '/portal/hr/setting/celebrationmanagement',
                  icon: 'flaticon-event-calendar-symbol',
                },
                {
                  title: 'Loại nghỉ phép',
                  page: '/portal/hr/setting/leavecategory',
                  icon: 'flaticon-calendar-3',
                },
                // {
                //   title: 'Duyệt nghỉ phép',
                //   page: '/portal/hr/setting/leaveapprovalrule',
                //   icon: 'flaticon-calendar-2',
                // },
                {
                  title: 'Ngày nghỉ phép còn lại',
                  page: '/portal/hr/setting/leaverestday',
                  icon: 'flaticon-open-box',
                },
              ],
            },
            {
              title: 'Loại tăng ca',
              bullet: 'dot',
              page: '/portal/hr/setting/otcategory',
              icon: 'flaticon-add',
            },
            {
              title: 'Loại hợp đồng',
              bullet: 'dot',
              icon: 'flaticon-folder-1',
              page: '/portal/hr/setting/contractcategory',
            },
            {
              title: 'Ca - Timesheet',
              bullet: 'dot',
              icon: 'flaticon-time',
              submenu: [
                {
                  title: 'Quản lý ca làm việc',
                  page: '/portal/hr/workingshift/appworkingshift',
                  bullet: 'dot',
                  icon: 'flaticon-clipboard',
                },
                {
                  title: 'Mã chấm công',
                  bullet: 'dot',
                  page: '/portal/hr/inoutlog/attendancecode',
                  icon: 'flaticon-user-ok',
                },
                {
                  title: 'Quản lý dự án cho timesheet',
                  page: '/portal/hr/setting/timesheetprojects',
                  icon: 'flaticon-network',
                },
              ],
            },
            {
              title: 'Phân quyền',
              bullet: 'dot',
              icon: 'flaticon-interface-7',
              Permissions: 'PERMISSION_MANGEMENT',
              submenu: [
                {
                  title: 'Quản lý phân quyền',
                  page: '/portal/hr/setting/permission/role-management',
                  icon: 'flaticon-security',
                },
              ],
            },
            {
              title: 'Thông tin doanh nghiệp',
              bullet: 'dot',
              page: '/portal/hr/setting/companymanagement',
              icon: 'flaticon-add',
            },
            {
              title: 'Công ty',
              bullet: 'dot',
              page: '/portal/hr/setting/company',
              icon: 'flaticon-home',
            },
            {
              title: 'Quote',
              bullet: 'dot',
              page: '/portal/hr/setting/quote',
              icon: 'flaticon-chat',
            },
            // {
            // 	title: 'Loại phòng ban',
            // 	page: '/portal/hr/setting/departmenttype',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-interface-7',
            // },
            // {
            // 	title: 'Phòng ban',
            // 	page:'/portal/hr/setting/department',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Chức vụ',
            // 	page:'/portal/hr/setting/position',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Loại chức vụ',
            // 	page:'/portal/hr/setting/positiontype',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Loại chứng chỉ',
            // 	page:'/portal/hr/setting/certificationcategory',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Loại bằng cấp',
            // 	page:'/portal/hr/setting/degreecategory',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục ngân hàng',
            // 	page:'/portal/hr/setting/bankmanagement',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Tình trạng hôn nhân',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục trạng thái làm việc',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục nghỉ lễ',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục loại tăng ca',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Ngày nghỉ còn lại',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục loại người phụ thuộc',
            // 	page:'/portal/hr/setting/dependencycategory',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục loại nghỉ phép',
            // 	page:'/portal/hr/setting/leavecategory',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục khen thưởng kỉ luật',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
            // {
            // 	title: 'Danh mục loại hợp đồng',
            // 	page:'/portal/hr/setting/contractcategory',
            // 	bullet: 'dot',
            // 	icon: 'flaticon-web',

            // },
          ],
        },
      ],
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
