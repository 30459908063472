/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface User { 
    id?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    cell_phone?: string;
    address?: string;
    disabled?: boolean;
    gender?: boolean;
    birthday?: Date;
    private_email?: string;
    phone_extension?: string;
    passport_id?: string;
    passport_expired?: Date;
    id_card_number?: string;
    id_card_issue_date?: Date;
    nationality?: string;
    avatar?: string;
    family_address_collection?: string;
    relative_info_collection?: string;
    created_by?: string;
    created_at?: Date;
    modified_by?: string;
    modified_at?: Date;
    social_info_collection?: string;
    birthplace?: string;
    religion?: string;
    ethnic?: string;
    department?: string;
    position?: string;
    id_card_issue_palce?: string;
    password_modified_at?: Date;
    employee_code?: string;
    domicile?: string;
    personal_tax_code?: string;
    passport_date?: Date;
    passport_place_of_issue?: string;
    avatar_name?: string;
    background?: string;
    job_description?: string;
    attachment_code?: string;
    company_phone_number?: string;
    personal_email?: string;
    emergency_contact?: string;
    emergency_relate?: string;
    emergency_tel?: string;
    emergency_phone?: string;
    emergency_address?: string;
    permanent_address?: string;
    ward?: string;
    province?: string;
    district?: string;
    current_address?: string;
    join_date?: Date;
    resignation_date?: Date;
    signature?: string;
    attendance_code?: number;
    cover_image?: string;
    full_name_unaccent?: string;
    integrity?: string;
    social_insurance_number?: string;
    health_insurance_number?: string;
    health_insurance_place?: string;
    health_insurance_place_code?: string;
    social_insurance_place?: string;
    job_description_path?: string;
    job_description_name?: string;
    no_timekeeping?: boolean;
    inversecreated_byNavigation?: Array<User>;
    inversemodified_byNavigation?: Array<User>;
}