/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SaSheetCell { 
    id?: string;
    sid?: string;
    ssid?: string;
    cid?: string;
    c_at?: Date;
    c_by?: string;
    m_at?: Date;
    m_by?: string;
    d?: string;
}