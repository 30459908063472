import {Injectable} from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';

import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../_services/auth.service';
import {Location} from '@angular/common';
import {HashService} from '../_services/hash.service';
import {routerNavigatedAction} from '@ngrx/router-store';

@Injectable()
export class HashRouteGuard implements CanActivate {
  destinationRoute;
  currRoute;
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private hashService: HashService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this.hashService.configs = route.data.hashconfig;
    const urlSegment = route.url.length;
    const url = state.url.split('#')[0].split(route.url[0].path)[1].split('/')
      .length;
    if (this.router.navigated) {
      console.log(state);
      console.log(state.url);
      this.router.events.subscribe((ev) => {
        if (ev instanceof NavigationEnd) {
          if (ev.url === state.url && url === urlSegment) {
            const urlHash = ev.url.split('#')[0];
            this.location.replaceState(
              urlHash.trim() +
                '/' +
                this.hashService.encryptData(
                  urlHash +
                    (this.hashService.configs.includes('include-email')
                      ? localStorage.getItem('logged-in')
                      : '')
                )
            );
          }
        }
      });
    } else {
      console.log("Forbiden URL");
      if (url <= urlSegment) {
        this.router.navigateByUrl('/error/404');
      }
    }
    return true;
  }
}
