/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface WorkflowItemTrasitionHistory { 
    id?: string;
    workflow_item_id?: string;
    from_acitivity_id?: string;
    to_acitivity_id?: string;
    created_at?: Date;
    created_by?: string;
    next_direction?: boolean;
    transition_id?: string;
    sign_type?: string;
    next_user?: string;
    is_internal_approve?: boolean;
    representative?: string;
}