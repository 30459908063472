import {DOCUMENT} from '@angular/common';
import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import * as CryptoJS from 'crypto-js';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {DialogComponent} from '../material/popups-and-modals/dialog/dialog.component';
import {BusinessCardService} from './service/business-card.service';

import {
  Contact,
  ContactField,
  ContactName,
  Contacts,
} from '@ionic-native/contacts';
@Component({
  selector: 'meu-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss'],
})
export class BusinessCardComponent implements OnInit {
  imgPath = environment.url_svg_local;
  imagePath = environment.url_image_local;
  isShowFront: boolean = true;
  userData = null;
  isChooseLanguage: boolean = true;
  tabSelector: string = 'firstTab';
  albumData: Array<any> = [];
  linkTest: string = 'https://www.youtube.com/embed/Kg_qK8uAaEY';
  //Url parameter
  email: string = '';
  userId: string = '';
  appLink: string = '';
  secretMd5Hash: string = '';

  constructor(
    private contacts: Contacts,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private BusinessCardService: BusinessCardService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.email = params['email'];
      this.userId = params['id'];
      this.appLink = 'app://clbdn/Profile?userId=' + params['id'];
      console.log('123');
    });

    // Generate the MD5 hash of the string "userID|userEmail"
    const base64String = btoa(`${this.userId}|${this.email}`); // Parse Base64 string
    const hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(base64String));
    const md5 = hash.toString(CryptoJS.enc.Hex);
    this.secretMd5Hash = md5;
    this.getData(this.secretMd5Hash.toUpperCase(), this.userId);
    this.getAlbumData(this.userId);
  }
  initContacts(): void {
    try {
      let contact: Contact = this.contacts.create();
      contact.name = new ContactName(null, 'Smith', 'John');
      contact.phoneNumbers = [new ContactField('mobile', '6471234567')];
      contact.save();
      this.contacts.pickContact();
    } catch (e) {
      console.error('Error saving contact.', e);
    }

    // If you want to open the native contacts screen and select the contacts from there use pickContact()
  }
  getData(secretStr: string, userId: string) {
    this.BusinessCardService.getBusinessCard(secretStr, userId).subscribe(
      (res) => {
        const {success, data} = res;

        if (success) {
          this.userData = data;
          this.userData.full_name =
            data.last_name + ' ' + data.middle_name + ' ' + data.firname;
          if (!data.avatar) {
            this.userData.avatarUrl =
              'https://clbdoanhnhanhotran.meu-solutions.com/api/users/businessCard' +
              'assets/media/users/default.jpg';
          } else {
            this.userData.avatarUrl =
              'https://clbdoanhnhanhotran.meu-solutions.com' + data.avatar;
          }
        } else {
          this.toastr.error(
            'Có lỗi xảy ra',
            'Lỗi khi lấy thông tin người dùng',
            {
              timeOut: 3000,
            }
          );
        }
      }
    );
  }
  getAlbumData(id: string) {
    this.BusinessCardService.getAlbumByUserID(id).subscribe(
      (res: {success: boolean; data: any}) => {
        const {success, data} = res;
        if (success) {
          this.albumData = data;
          this.cdr.detectChanges();
        } else {
          this.toastr.error(
            'Có lỗi xảy ra',
            'Lỗi khi lấy thông tin người dùng',
            {
              timeOut: 3000,
            }
          );
        }
      }
    );
  }
  onFlip() {
    this.isShowFront = !this.isShowFront;
  }
  onChangeLanguage() {
    this.isChooseLanguage = !this.isChooseLanguage;
  }
  goToUrl(url: string): void {
    window.open(url, '_blank');
    this.isShowFront = true;
  }
  handleSaveBtn(url: string): void {
    try {
      const userData = this.userData;
      const user_phoneLink =
        'app://clbdn/Profile?phone=' +
        this.userData.cell_phone +
        '&name=' +
        this.userData.full_name +
        '&email=' +
        this.userData.email;
      this.dialog.open(DialogComponent, {
        data: {url, userData, user_phoneLink},
        height: '230px',
        position: {top: '300px'},
        panelClass: 'dialog-container',
      });
    } catch (e) {
      console.log('12345', e);
    }
  }
  transform(value: any) {
    return this.domSanitizer.bypassSecurityTrustUrl(value);
  }
  onClickTab(tabName: string): void {
    this.tabSelector = tabName;
  }
}
