/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RecruitCvSubmitted { 
    id?: string;
    first_name?: string;
    phone?: string;
    email?: string;
    created_at?: Date;
    is_proceed?: boolean;
    recruit_id?: string;
    last_name?: string;
}