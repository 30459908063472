/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrTimesheetWeek { 
    id?: string;
    staff_id?: string;
    monday_date?: Date;
    submited?: boolean;
    approver_id?: string;
    approved_status_id?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    week_notes?: string;
}