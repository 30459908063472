// Angular
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
// Partials
// Pages
import {MatTooltipModule} from '@angular/material/tooltip';
import {CoreModule} from '../../core/core.module';
import {MailModule} from './apps/mail/mail.module';
import {BusinessCardComponent} from './business-card/business-card.component';
import {MyPageComponent} from './my-page/my-page.component';

@NgModule({
  declarations: [MyPageComponent, BusinessCardComponent],
  exports: [],
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    MailModule,
    MatTooltipModule,
  ],
  providers: [],
})
export class PagesModule {}
