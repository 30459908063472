/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrLeaveAccruedDay { 
    id?: string;
    staff_id?: string;
    leave_category_id?: string;
    days?: number;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    is_seniority?: boolean;
    expiry?: Date;
    available_days?: number;
    note?: string;
    batch_job_unique?: string;
}