import {NgModule, ModuleWithProviders, SkipSelf, Optional} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';

import {AbsentRequestService} from './api/absentRequest.service';
import {ApprovedStatusService} from './api/approvedStatus.service';
import {AttachmentService} from './api/attachment.service';
import {AuthenticateService} from './api/authenticate.service';
import {BackgroundJobService} from './api/backgroundJob.service';
import {BankCollectionService} from './api/bankCollection.service';
import {CommentService} from './api/comment.service';
import {CompanyInfoService} from './api/companyInfo.service';
import {DbdBoardService} from './api/dbdBoard.service';
import {DbdSectionService} from './api/dbdSection.service';
import {DbdWidgetCollectionService} from './api/dbdWidgetCollection.service';
import {DebugService} from './api/debug.service';
import {EzLogService} from './api/ezLog.service';
import {FcmTokenService} from './api/fcmToken.service';
import {FileDownloadMngtService} from './api/fileDownloadMngt.service';
import {HrCelebrationDayService} from './api/hrCelebrationDay.service';
import {HrCertificationCategoryService} from './api/hrCertificationCategory.service';
import {HrContractService} from './api/hrContract.service';
import {HrContractCategoryService} from './api/hrContractCategory.service';
import {HrDegreeCategoryService} from './api/hrDegreeCategory.service';
import {HrDepartmentService} from './api/hrDepartment.service';
import {HrDepartmentTypeService} from './api/hrDepartmentType.service';
import {HrDependencyCategoryService} from './api/hrDependencyCategory.service';
import {HrDependencyPeopleService} from './api/hrDependencyPeople.service';
import {HrEmergencyContactService} from './api/hrEmergencyContact.service';
import {HrInOutDrawLogService} from './api/hrInOutDrawLog.service';
import {HrInOutLogService} from './api/hrInOutLog.service';
import {HrLeaveAccruedDayService} from './api/hrLeaveAccruedDay.service';
import {HrLeaveApproveRuleService} from './api/hrLeaveApproveRule.service';
import {HrLeaveCategoryService} from './api/hrLeaveCategory.service';
import {HrLeaveRestDayService} from './api/hrLeaveRestDay.service';
import {HrOrgchartService} from './api/hrOrgchart.service';
import {HrOtCategoryService} from './api/hrOtCategory.service';
import {HrOtCategoryWorkingShiftService} from './api/hrOtCategoryWorkingShift.service';
import {HrPositionService} from './api/hrPosition.service';
import {HrPositionTypeService} from './api/hrPositionType.service';
import {HrSalaryHistoryService} from './api/hrSalaryHistory.service';
import {HrTemplateService} from './api/hrTemplate.service';
import {HrTimesheetService} from './api/hrTimesheet.service';
import {HrTimesheetProjectService} from './api/hrTimesheetProject.service';
import {HrUserExtraContactService} from './api/hrUserExtraContact.service';
import {HrUserStatusService} from './api/hrUserStatus.service';
import {LeaveCategoryService} from './api/leaveCategory.service';
import {LeaveDaysService} from './api/leaveDays.service';
import {MailMergeService} from './api/mailMerge.service';
import {NotificationService} from './api/notification.service';
import {OfficeService} from './api/office.service';
import {OfficeTypeService} from './api/officeType.service';
import {OfficeUsersService} from './api/officeUsers.service';
import {OtService} from './api/ot.service';
import {PRoleService} from './api/pRole.service';
import {QuotationService} from './api/quotation.service';
import {RecruitService} from './api/recruit.service';
import {RecruitCvSubmittedService} from './api/recruitCvSubmitted.service';
import {SaBookService} from './api/saBook.service';
import {SaPayslipItemService} from './api/saPayslipItem.service';
import {SaPayslipSectionService} from './api/saPayslipSection.service';
import {SaSheetColumnGroupService} from './api/saSheetColumnGroup.service';
import {SaSheetProceedDataService} from './api/saSheetProceedData.service';
import {SaSheetTableService} from './api/saSheetTable.service';
import {SaSheetWorkingTimeDataService} from './api/saSheetWorkingTimeData.service';
import {TestInstanceService} from './api/testInstance.service';
import {TimeUnitService} from './api/timeUnit.service';
import {UserBankService} from './api/userBank.service';
import {UserCertificationService} from './api/userCertification.service';
import {UserDegreeService} from './api/userDegree.service';
import {UsersService} from './api/users.service';
import {WorkflowService} from './api/workflow.service';
import {WorkflowBookmarkService} from './api/workflowBookmark.service';
import {WorkflowItemService} from './api/workflowItem.service';
import {WorkflowItemAttachmentDataService} from './api/workflowItemAttachmentData.service';
import {WorkflowItemFieldDataService} from './api/workflowItemFieldData.service';
import {WorkflowTreeService} from './api/workflowTree.service';
import {WorkingShiftService} from './api/workingShift.service';
import {DialogComponent} from './views/pages/material/popups-and-modals/dialog/dialog.component';
@NgModule({
  imports: [],
  declarations: [DialogComponent],
  exports: [],
  providers: [
    AbsentRequestService,
    ApprovedStatusService,
    AttachmentService,
    AuthenticateService,
    BackgroundJobService,
    BankCollectionService,
    CommentService,
    CompanyInfoService,
    DbdBoardService,
    DbdSectionService,
    DbdWidgetCollectionService,
    DebugService,
    EzLogService,
    FcmTokenService,
    FileDownloadMngtService,
    HrCelebrationDayService,
    HrCertificationCategoryService,
    HrContractService,
    HrContractCategoryService,
    HrDegreeCategoryService,
    HrDepartmentService,
    HrDepartmentTypeService,
    HrDependencyCategoryService,
    HrDependencyPeopleService,
    HrEmergencyContactService,
    HrInOutDrawLogService,
    HrInOutLogService,
    HrLeaveAccruedDayService,
    HrLeaveApproveRuleService,
    HrLeaveCategoryService,
    HrLeaveRestDayService,
    HrOrgchartService,
    HrOtCategoryService,
    HrOtCategoryWorkingShiftService,
    HrPositionService,
    HrPositionTypeService,
    HrSalaryHistoryService,
    HrTemplateService,
    HrTimesheetService,
    HrTimesheetProjectService,
    HrUserExtraContactService,
    HrUserStatusService,
    LeaveCategoryService,
    LeaveDaysService,
    MailMergeService,
    NotificationService,
    OfficeService,
    OfficeTypeService,
    OfficeUsersService,
    OtService,
    PRoleService,
    QuotationService,
    RecruitService,
    RecruitCvSubmittedService,
    SaBookService,
    SaPayslipItemService,
    SaPayslipSectionService,
    SaSheetColumnGroupService,
    SaSheetProceedDataService,
    SaSheetTableService,
    SaSheetWorkingTimeDataService,
    TestInstanceService,
    TimeUnitService,
    UserBankService,
    UserCertificationService,
    UserDegreeService,
    UsersService,
    WorkflowService,
    WorkflowBookmarkService,
    WorkflowItemService,
    WorkflowItemAttachmentDataService,
    WorkflowItemFieldDataService,
    WorkflowTreeService,
    WorkingShiftService,
    DialogComponent,
  ],
})
export class ApiModule {
  public static forRoot(
    configurationFactory: () => Configuration
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}],
    };
  }

  constructor(
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error(
        'ApiModule is already loaded. Import in your base AppModule only.'
      );
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
