export * from './absentRequest.service';
import { AbsentRequestService } from './absentRequest.service';
export * from './approvedStatus.service';
import { ApprovedStatusService } from './approvedStatus.service';
export * from './attachment.service';
import { AttachmentService } from './attachment.service';
export * from './authenticate.service';
import { AuthenticateService } from './authenticate.service';
export * from './backgroundJob.service';
import { BackgroundJobService } from './backgroundJob.service';
export * from './bankCollection.service';
import { BankCollectionService } from './bankCollection.service';
export * from './comment.service';
import { CommentService } from './comment.service';
export * from './companyInfo.service';
import { CompanyInfoService } from './companyInfo.service';
export * from './dbdBoard.service';
import { DbdBoardService } from './dbdBoard.service';
export * from './dbdSection.service';
import { DbdSectionService } from './dbdSection.service';
export * from './dbdWidgetCollection.service';
import { DbdWidgetCollectionService } from './dbdWidgetCollection.service';
export * from './debug.service';
import { DebugService } from './debug.service';
export * from './ezLog.service';
import { EzLogService } from './ezLog.service';
export * from './fcmToken.service';
import { FcmTokenService } from './fcmToken.service';
export * from './fileDownloadMngt.service';
import { FileDownloadMngtService } from './fileDownloadMngt.service';
export * from './hrCelebrationDay.service';
import { HrCelebrationDayService } from './hrCelebrationDay.service';
export * from './hrCertificationCategory.service';
import { HrCertificationCategoryService } from './hrCertificationCategory.service';
export * from './hrContract.service';
import { HrContractService } from './hrContract.service';
export * from './hrContractCategory.service';
import { HrContractCategoryService } from './hrContractCategory.service';
export * from './hrDegreeCategory.service';
import { HrDegreeCategoryService } from './hrDegreeCategory.service';
export * from './hrDepartment.service';
import { HrDepartmentService } from './hrDepartment.service';
export * from './hrDepartmentType.service';
import { HrDepartmentTypeService } from './hrDepartmentType.service';
export * from './hrDependencyCategory.service';
import { HrDependencyCategoryService } from './hrDependencyCategory.service';
export * from './hrDependencyPeople.service';
import { HrDependencyPeopleService } from './hrDependencyPeople.service';
export * from './hrEmergencyContact.service';
import { HrEmergencyContactService } from './hrEmergencyContact.service';
export * from './hrInOutDrawLog.service';
import { HrInOutDrawLogService } from './hrInOutDrawLog.service';
export * from './hrInOutLog.service';
import { HrInOutLogService } from './hrInOutLog.service';
export * from './hrLeaveAccruedDay.service';
import { HrLeaveAccruedDayService } from './hrLeaveAccruedDay.service';
export * from './hrLeaveApproveRule.service';
import { HrLeaveApproveRuleService } from './hrLeaveApproveRule.service';
export * from './hrLeaveCategory.service';
import { HrLeaveCategoryService } from './hrLeaveCategory.service';
export * from './hrLeaveRestDay.service';
import { HrLeaveRestDayService } from './hrLeaveRestDay.service';
export * from './hrOrgchart.service';
import { HrOrgchartService } from './hrOrgchart.service';
export * from './hrOtCategory.service';
import { HrOtCategoryService } from './hrOtCategory.service';
export * from './hrOtCategoryWorkingShift.service';
import { HrOtCategoryWorkingShiftService } from './hrOtCategoryWorkingShift.service';
export * from './hrPosition.service';
import { HrPositionService } from './hrPosition.service';
export * from './hrPositionType.service';
import { HrPositionTypeService } from './hrPositionType.service';
export * from './hrSalaryHistory.service';
import { HrSalaryHistoryService } from './hrSalaryHistory.service';
export * from './hrTemplate.service';
import { HrTemplateService } from './hrTemplate.service';
export * from './hrTimesheet.service';
import { HrTimesheetService } from './hrTimesheet.service';
export * from './hrTimesheetProject.service';
import { HrTimesheetProjectService } from './hrTimesheetProject.service';
export * from './hrUserExtraContact.service';
import { HrUserExtraContactService } from './hrUserExtraContact.service';
export * from './hrUserStatus.service';
import { HrUserStatusService } from './hrUserStatus.service';
export * from './leaveCategory.service';
import { LeaveCategoryService } from './leaveCategory.service';
export * from './leaveDays.service';
import { LeaveDaysService } from './leaveDays.service';
export * from './mailMerge.service';
import { MailMergeService } from './mailMerge.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './office.service';
import { OfficeService } from './office.service';
export * from './officeType.service';
import { OfficeTypeService } from './officeType.service';
export * from './officeUsers.service';
import { OfficeUsersService } from './officeUsers.service';
export * from './ot.service';
import { OtService } from './ot.service';
export * from './pRole.service';
import { PRoleService } from './pRole.service';
export * from './quotation.service';
import { QuotationService } from './quotation.service';
export * from './recruit.service';
import { RecruitService } from './recruit.service';
export * from './recruitCvSubmitted.service';
import { RecruitCvSubmittedService } from './recruitCvSubmitted.service';
export * from './saBook.service';
import { SaBookService } from './saBook.service';
export * from './saPayslipItem.service';
import { SaPayslipItemService } from './saPayslipItem.service';
export * from './saPayslipSection.service';
import { SaPayslipSectionService } from './saPayslipSection.service';
export * from './saSheetColumnGroup.service';
import { SaSheetColumnGroupService } from './saSheetColumnGroup.service';
export * from './saSheetProceedData.service';
import { SaSheetProceedDataService } from './saSheetProceedData.service';
export * from './saSheetTable.service';
import { SaSheetTableService } from './saSheetTable.service';
export * from './saSheetWorkingTimeData.service';
import { SaSheetWorkingTimeDataService } from './saSheetWorkingTimeData.service';
export * from './testInstance.service';
import { TestInstanceService } from './testInstance.service';
export * from './timeUnit.service';
import { TimeUnitService } from './timeUnit.service';
export * from './userBank.service';
import { UserBankService } from './userBank.service';
export * from './userCertification.service';
import { UserCertificationService } from './userCertification.service';
export * from './userDegree.service';
import { UserDegreeService } from './userDegree.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from './workflow.service';
import { WorkflowService } from './workflow.service';
export * from './workflowBookmark.service';
import { WorkflowBookmarkService } from './workflowBookmark.service';
export * from './workflowItem.service';
import { WorkflowItemService } from './workflowItem.service';
export * from './workflowItemAttachmentData.service';
import { WorkflowItemAttachmentDataService } from './workflowItemAttachmentData.service';
export * from './workflowItemFieldData.service';
import { WorkflowItemFieldDataService } from './workflowItemFieldData.service';
export * from './workflowTree.service';
import { WorkflowTreeService } from './workflowTree.service';
export * from './workingShift.service';
import { WorkingShiftService } from './workingShift.service';
export const APIS = [AbsentRequestService, ApprovedStatusService, AttachmentService, AuthenticateService, BackgroundJobService, BankCollectionService, CommentService, CompanyInfoService, DbdBoardService, DbdSectionService, DbdWidgetCollectionService, DebugService, EzLogService, FcmTokenService, FileDownloadMngtService, HrCelebrationDayService, HrCertificationCategoryService, HrContractService, HrContractCategoryService, HrDegreeCategoryService, HrDepartmentService, HrDepartmentTypeService, HrDependencyCategoryService, HrDependencyPeopleService, HrEmergencyContactService, HrInOutDrawLogService, HrInOutLogService, HrLeaveAccruedDayService, HrLeaveApproveRuleService, HrLeaveCategoryService, HrLeaveRestDayService, HrOrgchartService, HrOtCategoryService, HrOtCategoryWorkingShiftService, HrPositionService, HrPositionTypeService, HrSalaryHistoryService, HrTemplateService, HrTimesheetService, HrTimesheetProjectService, HrUserExtraContactService, HrUserStatusService, LeaveCategoryService, LeaveDaysService, MailMergeService, NotificationService, OfficeService, OfficeTypeService, OfficeUsersService, OtService, PRoleService, QuotationService, RecruitService, RecruitCvSubmittedService, SaBookService, SaPayslipItemService, SaPayslipSectionService, SaSheetColumnGroupService, SaSheetProceedDataService, SaSheetTableService, SaSheetWorkingTimeDataService, TestInstanceService, TimeUnitService, UserBankService, UserCertificationService, UserDegreeService, UsersService, WorkflowService, WorkflowBookmarkService, WorkflowItemService, WorkflowItemAttachmentDataService, WorkflowItemFieldDataService, WorkflowTreeService, WorkingShiftService];
