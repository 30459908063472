/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrInOutLog { 
    id?: string;
    staff_id?: string;
    time_input?: Date;
    time_ouput?: Date;
    break_time_second?: number;
    time_for_salary_second?: number;
    late_input_time_second?: number;
    soon_output_time_second?: number;
    working_ship_id?: string;
    absent_id?: string;
    due_start?: Date;
    due_finish?: Date;
    forgot_timekeeping?: boolean;
}