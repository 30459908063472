/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrSalaryHistory { 
    id?: string;
    staff_id?: string;
    from_date?: Date;
    to_date?: Date;
    basic_salary?: number;
    gross_salary?: number;
    net_salary?: number;
    pay_range?: number;
    is_active?: boolean;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    lunch_cost?: number;
    phone_subsidy?: number;
    commuting_subsidy?: number;
    house_subsidy?: number;
    baby_subsidy?: string;
}