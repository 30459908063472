/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface RecruitRequest { 
    id?: string;
    name?: string;
    description?: string;
    position_id?: string;
    quantity?: number;
    plan_start?: Date;
    plan_finish?: Date;
    recruit_process_id?: string;
    approver_id?: string;
    approved_status_id?: string;
    approved_date?: Date;
    code?: string;
    job_description?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    is_replace?: boolean;
    category_id?: number;
    salary?: string;
    is_public?: boolean;
}