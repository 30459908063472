/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SaSheet { 
    id?: string;
    name?: string;
    sa_book_id?: string;
    start_date?: Date;
    end_date?: Date;
    excel_file_path?: string;
    is_locked?: boolean;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    description?: string;
    excel_file_name?: string;
}