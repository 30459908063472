/**
 * MEU ERP API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface HrLeaveCategory { 
    id?: string;
    name?: string;
    description?: string;
    is_salary?: boolean;
    code?: string;
    created_at?: Date;
    created_by?: string;
    modified_at?: Date;
    modified_by?: string;
    activated?: boolean;
    rule_add_day?: number;
    rule_add_day_cycle_month?: number;
    rule_senority_enabled?: boolean;
    rule_senority_add?: number;
    rule_senority_cycle_year?: number;
    rule_add_leave_trigger_date?: number;
    allow_leave_borrow?: boolean;
    maximum_borrow_day?: number;
    keep_leave_after_month?: number;
    keep_leave_maximum_day?: number;
    add_leave_after_join_month?: number;
    use_leave_after_join_month?: number;
    leave_of_second_half_month?: number;
    from_date?: Date;
    to_date?: Date;
    keep_leave_later_year?: boolean;
    uncount_after_use?: boolean;
    expired_after_month?: number;
}