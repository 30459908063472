import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import {FcmTokenService} from '..';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  messagePayload;
  constructor(private fcmTokenService: FcmTokenService) {}

  requestPermission() {
    if (!environment.firebase) {
      return;
    }
    const messaging = getMessaging();

    getToken(messaging, {vapidKey: environment.firebase.vapidKey})
      .then((currentToken) => {
        if (currentToken) {
          this.fcmTokenService
            .apiFirebaseTokenPost({token: currentToken})
            .subscribe((res) => {
              localStorage.setItem(
                environment.authTokenKey + 'fb',
                currentToken
              );
              console.log(
                'register notification ' + (res.success ? 'success' : 'fail')
              );
              this.listen();
            });
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          );
          // ...
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  }

  listen() {
    if (!environment.firebase) {
      return;
    }
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.messagePayload = payload;
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: 'https://bk-hr.thanglongreal.vn/public//company_info/2410202117284005686259.jpeg',
      };

      // eslint-disable-next-line no-restricted-globals
      const notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = (event: any) => {
        if (
          this.messagePayload &&
          this.messagePayload.data &&
          this.messagePayload.data.link
        ) {
          window.open(this.messagePayload.notification.data.url, '_blank');
        }
      };
    });
  }
}
